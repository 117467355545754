import { useState } from "react";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import {
  DatePickerWidget,
  FileWidget,
  FundCalculatorWidget,
  SelectWidget,
  TextWidget,
  YesNoWidget,
} from "@/components/RJSF/widgets";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomObjectFieldTemplate as ObjectFieldTemplate } from "@/components/RJSF/templates/CustomObjectFieldTemplate";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import { GET_SCHEMA_BY_KEY, GET_SIGNLE_TASK } from "@/shared/graphql";
import { useMutation, useQuery } from "@apollo/client";
import { Loading } from "@/components/Loading";
import {
  REJECT_TASK,
  UPDATE_NEGOTIATOR_TASK,
} from "@/shared/graphql/mutations";
import { InfoWidget } from "@/components/RJSF/widgets/InfoWidget/InfoWidget";
import {
  fetchAllCitiesByRegionId,
  fetchAllDistricts,
  fetchAllRegions,
  fetchAllZonesByRegionId,
} from "@/shared/graphql/services/layers";
import { SignedUrlService } from "@/shared/graphql/services/signedUrl.service";
import { graphqlErrorHandler } from "@/shared/utils";
import { RootState } from "@/shared/store";
import { useDispatch, useSelector } from "react-redux";
import { Notes } from "@/components/Notes";
import { setChoices } from "@/shared/store/slices/installmentSlice";
import {
  insertCitiesInFormSchema,
  insertDistrictsInFormSchema,
} from "@/modules/app/customer-service/Tasks/Task/TaskForm/TaskFormUtils";
import { CallLogs } from "@/modules/app/customer-service/Tasks/Task/CallLogs";
// import schema7 from "@/schemas/7-negotiator-beneficiary-data.json";
// import uiSchema7 from "@/schemas/ui-schemas/7-negotiator-beneficiary-data.json";

export const BeneficiaryForm = ({
  setSelectedStep,
  refetchTask,
  canSubmit,
}) => {
  const [formSchema, setFormSchema] = useState({});
  const [regions, setRegions] = useState({});
  const [cities, setCities] = useState<{ [x: string]: number }>({});
  const [formData, setFormData] = useState({
    contact: {},
    financialData: {},
    locationPreferences: {},
    request: {},
    location: {},
    familyData: {},
    personalData: {},
    enthusiasmLevel: {},
    supportPackages: {},
    professionalData: {},
    basicTransactionData: {},
    financialPreferences: {},
    negotiatorContactTime: {},
    realEstatePreferences: {},
    personalRealEstateData: {},
  });
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const UploadSignedUrlFile = new SignedUrlService();
  const [rejectTaskMutate, { loading: isRejectLoading }] =
    useMutation(REJECT_TASK);

  const { t } = useTranslation();
  const { taskId } = useParams();
  const [updateTask, { loading: updateTaskLoading }] = useMutation(
    UPDATE_NEGOTIATOR_TASK
  );

  const { loading: taskLoading, data: taskRes } = useQuery(GET_SIGNLE_TASK, {
    variables: { pk: taskId, role: userInfo?.role?.queryVar },
    onCompleted: (res) => {
      const task = res?.tasks?.data?.at(0);
      if (task?.formData?.negotiator?.drafts?.negotiatorData) {
        setFormData((prev) => ({
          ...prev,
          ...task?.formData?.negotiator?.drafts?.negotiatorData,
        }));
        return;
      }

      const resFormData = JSON.parse(
        JSON.stringify(task?.formData?.negotiator?.negotiatorData)
      );

      setFormData((prev) => ({ ...prev, ...resFormData }));
    },
  });

  const { data, loading: schemaLoading } = useQuery(GET_SCHEMA_BY_KEY, {
    variables: { key: "negotiation-updated-data" },
    skip: !taskRes,
    onCompleted: async (res) => {
      const requestedFromSchema = JSON.parse(
        JSON.stringify(res?.schemas?.data?.at(0)?.jsonSchema?.form)
      );
      const clonedFormData = JSON.parse(
        JSON.stringify(
          taskRes?.tasks?.data?.at(0)?.formData?.negotiator?.drafts
            ?.negotiatorData ||
            taskRes?.tasks?.data?.at(0)?.formData?.negotiator?.negotiatorData
        )
      );
      // fetching regions
      const regionsRes = await fetchAllRegions();
      const allRegions = regionsRes?.data?.regions?.data;
      const regionsMap = regionsRes?.data?.regions?.data?.reduce(
        (hashmap, item) => {
          hashmap[item?.sourceProperties.region_name] =
            item?.sourceProperties?.id;
          return hashmap;
        },
        {}
      );

      // set region id
      clonedFormData.locationPreferences.regionId =
        regionsMap[clonedFormData.locationPreferences.preferredRegion];
      setRegions(regionsMap);

      if (requestedFromSchema?.definitions?.location?.properties?.region) {
        requestedFromSchema.definitions.location.properties.region.enum =
          allRegions?.map((region) => region.sourceProperties.region_name);
      }

      if (
        requestedFromSchema?.definitions?.locationPreferences?.properties
          ?.preferredRegion
      ) {
        requestedFromSchema.definitions.locationPreferences.properties.preferredRegion.enum =
          allRegions?.map((region) => region.sourceProperties.region_name);
      }

      // checking if the region default value exists
      const prefilledRegion =
        clonedFormData?.locationPreferences?.preferredRegion ||
        clonedFormData?.preferredRegion;
      if (regionsMap[prefilledRegion]) {
        // fetchCities by region id
        const citiesRes = await fetchAllCitiesByRegionId(
          regionsMap[prefilledRegion]?.toString()
        );

        const allCities = citiesRes?.data?.cities?.data;
        const citiesMap = allCities.reduce((hashmap, item) => {
          hashmap[item?.sourceProperties.city_name] =
            item?.sourceProperties?.id;
          return hashmap;
        }, {});
        // set city id
        clonedFormData.locationPreferences.cityId =
          citiesMap[clonedFormData.locationPreferences.preferredCity];
        setCities(citiesMap);
        const cityId = clonedFormData.locationPreferences.cityId;
        const zonesRes = await fetchAllZonesByRegionId(cityId);

        const allZones = zonesRes?.data?.zones?.data?.map((zone) => ({
          id: zone.sourceProperties.id,
          label: zone.sourceProperties?.zone_name,
        }));

        if (
          requestedFromSchema?.definitions?.locationPreferences?.properties
            ?.mainDivision
        ) {
          if (allZones.length) {
            requestedFromSchema.definitions.locationPreferences.properties.mainDivision.items.enum =
              allZones;
            requestedFromSchema.definitions.locationPreferences.properties.mainDivision.items.enumNames =
              allZones.map((zone) => zone.label);
          } else {
            delete requestedFromSchema.definitions.locationPreferences
              .properties.mainDivision.items.enum;
            delete requestedFromSchema.definitions.locationPreferences
              .properties.mainDivision.items.enumNames;
          }
        }

        if (
          requestedFromSchema?.definitions?.locationPreferences?.properties
            ?.preferredCity
        ) {
          requestedFromSchema.definitions.locationPreferences.properties.preferredCity.enum =
            allCities?.map((city) => city.sourceProperties.city_name);
        }
      } else {
        delete clonedFormData.locationPreferences.preferredRegion;
        delete clonedFormData.locationPreferences.preferredCity;
      }

      setRegions(regionsMap);

      // inserting installments options
      if (clonedFormData?.financialData?.installmentChoice === undefined) {
        clonedFormData.financialData.installmentChoice = null;
      }
      updateInstallmentChoices(clonedFormData.financialData);

      setFormSchema(requestedFromSchema);
    },
  });

  const updateInstallmentChoices = (financialData) => {
    dispatch(setChoices(financialData));
  };

  const onFileChange = async (data: Array<{ file: File }> | { file: File }) => {
    let response: any;
    try {
      if (Array.isArray(data)) {
        const promises = data.map(({ file }) =>
          UploadSignedUrlFile.uploadViaSignedUrl(file, taskId as string)
        );
        response = await Promise.all(promises);
      } else {
        response = await UploadSignedUrlFile.uploadViaSignedUrl(
          data.file,
          taskId as string
        );
      }
    } catch (error) {
      console.error({ error });
    }
    return response;
  };

  const onFormChange = async (form: any, fieldId) => {
    const clonedFormData = JSON.parse(JSON.stringify(form?.formData));
    let clonedFormSchema = JSON.parse(JSON.stringify(formSchema));

    if (fieldId === "root_location_region") {
      if (clonedFormData?.location?.city) {
        delete clonedFormData.location?.city;
      }
      const citiesRes = await fetchAllCitiesByRegionId(
        regions[form?.formData?.location?.region]?.toString()
      );

      const allCities = citiesRes?.data?.cities?.data;
      if (clonedFormSchema?.definitions?.location?.properties?.city) {
        clonedFormSchema.definitions.location.properties.city.enum =
          allCities?.map((city) => city.sourceProperties.city_name);
      }
    }

    if (fieldId === "root_locationPreferences_preferredRegion") {
      if (clonedFormData?.locationPreferences?.preferredCity) {
        delete clonedFormData.locationPreferences.preferredCity;
      }
      if (clonedFormData?.locationPreferences?.mainDivision) {
        delete clonedFormData.locationPreferences.mainDivision;
      }

      // insert region id
      clonedFormData.locationPreferences.regionId =
        regions[clonedFormData?.locationPreferences?.preferredRegion];

      const citiesRes = await fetchAllCitiesByRegionId(
        regions[
          form?.formData?.locationPreferences?.preferredRegion
        ]?.toString()
      );
      const allCities = citiesRes?.data?.cities?.data;
      const citiessMap = allCities.reduce((hashmap, item) => {
        hashmap[item?.sourceProperties.city_name] = item?.sourceProperties?.id;
        return hashmap;
      }, {});
      setCities(citiessMap);

      clonedFormSchema = insertCitiesInFormSchema(clonedFormSchema, allCities);
      setFormSchema(clonedFormSchema);
    }
    if (fieldId === "root_locationPreferences_preferredCity") {
      if (clonedFormData?.locationPreferences?.mainDivision) {
        delete clonedFormData.locationPreferences.mainDivision;
      }

      // insert city id
      clonedFormData.locationPreferences.cityId =
        cities[clonedFormData?.locationPreferences?.preferredCity];

      const zonesRes = await fetchAllZonesByRegionId(
        cities[clonedFormData?.locationPreferences?.preferredCity]?.toString()
      );

      // zone
      const allZones = zonesRes?.data?.zones?.data?.map((zone) => ({
        id: zone.sourceProperties.id,
        label: zone.sourceProperties?.zone_name,
      }));

      if (
        clonedFormSchema?.definitions?.locationPreferences?.properties
          ?.mainDivision
      ) {
        if (allZones.length) {
          clonedFormSchema.definitions.locationPreferences.properties.mainDivision.items.enum =
            allZones;
          clonedFormSchema.definitions.locationPreferences.properties.mainDivision.items.enumNames =
            allZones.map((zone) => zone.label);
        } else {
          delete clonedFormSchema.definitions.locationPreferences.properties
            .mainDivision.items.enum;
          delete clonedFormSchema.definitions.locationPreferences.properties
            .mainDivision.items.enumNames;
        }
      }
    }
    if (fieldId === "root_locationPreferences_mainDivision") {
      const cityId = form.formData?.locationPreferences?.cityId;
      const zonesId = form.formData?.locationPreferences?.mainDivision
        ?.map((zone) => zone.id)
        .filter((zone) => !!zone);
      const districtsRes = await fetchAllDistricts(cityId, zonesId);
      const allDistricts = districtsRes?.data?.districts?.data?.map(
        (district) => ({
          id: district.sourceProperties.id,
          label: district.sourceProperties?.district_name,
        })
      );
      clonedFormSchema = insertDistrictsInFormSchema(
        clonedFormSchema,
        allDistricts
      );
    }

    if (
      [
        "root_financialData_salary",
        "root_financialData_monthlyDeduction",
        "root_financialData_remainingDurationMonths",
      ].includes(fieldId)
    ) {
      clonedFormData.financialData.installmentChoice = null;
      updateInstallmentChoices(clonedFormData.financialData);
    }
    clonedFormData.locationPreferences.district =
      clonedFormData.locationPreferences.district.filter(
        (d) => Object.keys(d).length > 0
      );

    setFormSchema(clonedFormSchema);
    setFormData((prev) => ({ ...prev, ...clonedFormData }));
  };

  const saveAsDraft = () => {
    const submittedData = { negotiatorData: formData };
    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: "negotiation-updated-data",
          formData: JSON.stringify(submittedData),
          isDraft: true,
        },
      },
    })
      .then(() => {
        toast.success(t("data has been drafted"));
        navigate("../tasks");
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  const onSubmit = (form) => {
    const submittedData = { negotiatorData: form.formData };

    const isString = (value) =>
      typeof value === "string" || value instanceof String;
    const clonedFormData = JSON.parse(JSON.stringify(form.formData));
    const mainDivision = clonedFormData.locationPreferences.mainDivision;

    clonedFormData.locationPreferences.mainDivision = mainDivision?.map(
      (el) => {
        if (isString(el)) {
          return {
            id: null,
            label: el,
          };
        }
        return el;
      }
    );

    const isRejected =
      clonedFormData?.contact?.willingnessToContinueProgram ===
      "لا يرغب في استكمال البرنامج";

    if (isRejected) {
      rejectTaskMutate({
        variables: {
          taskInput: {
            taskId,
            formSchemaKey: "negotiation-updated-data",
            formData: JSON.stringify(submittedData),
          },
        },
      })
        .then(() => {
          navigate("../tasks");
          toast.success(t("Task Rejected Successfully"));
        })
        .catch((err) => {
          graphqlErrorHandler(err);
        });
      return;
    }

    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: "negotiation-updated-data",
          formData: JSON.stringify(submittedData),
          isDraft: false,
          status: "SECOND_LEVEL_PREFERENCES",
        },
      },
    })
      .then(() => {
        refetchTask();
        toast.success(t("Data has been saved successfully"));
        setSelectedStep((prev) => prev + 2);
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  if (taskLoading) return <Loading />;

  const schema = data?.schemas?.data?.at(0)?.jsonSchema;
  const task = taskRes?.tasks?.data?.at(0);
  const uiSchema = schema?.UISchema;
  if (schemaLoading || taskLoading || !formSchema) return <Loading />;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={8}>
        <Form
          formContext={{ onFileChange }}
          schema={formSchema || {}}
          uiSchema={uiSchema}
          validator={validator}
          showErrorList={false}
          onChange={onFormChange}
          noHtml5Validate
          formData={formData}
          widgets={{
            FileWidget,
            YesNoWidget,
            TextWidget,
            InfoWidget,
            SelectWidget,
            DateWidget: DatePickerWidget,
          }}
          fields={{ FundCalculatorWidget }}
          templates={{ ObjectFieldTemplate }}
          onSubmit={onSubmit}
        >
          <Button
            disabled={!canSubmit || updateTaskLoading || isRejectLoading}
            onClick={saveAsDraft}
            variant="contained"
            sx={{ mt: 2, mr: 1 }}
          >
            {t("save and complete later")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
            disabled={!canSubmit || updateTaskLoading || isRejectLoading}
          >
            {t("save")}
          </Button>
        </Form>
      </Grid>
      <Grid item xs={12} md={4}>
        <Notes />
        <CallLogs
          title="call logs from customer services"
          userType="customer service"
          callLogs={task?.callLogs?.customer_service}
        />
        <CallLogs userType="negotiator" callLogs={task?.callLogs?.negotiator} />
      </Grid>
    </Grid>
  );
};
